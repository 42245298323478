import Vue from 'vue';
import {Logger} from '@teemill/common/classes';

const logger = new Logger('#3f51b5', 'Dynamic Store');

const store = {
  namespaced: true,
  state: {},
  mutations: {
    onRegisterProperty(state, data) {
      logger.log(`registering prop ${data.prop}`);
      state[data.prop] = data.value;
    },
    onSetPropertyState(state, data) {
      logger.log(`updating prop ${data.prop}`);
      state[data.prop] = data.value;
    },
  },
};

export default {
  data() {
    return {
      uniqueVuexStoreName: Math.random().toString(36).substring(7),
      customStoreOptions: false,
      isVuexStoreRegistered: false,
      uniqueVuexStore: false,
      options: {
        unregisterOnDeath: true,
      },
    };
  },

  methods: {
    getVuexStoreName() {
      if (!this.customStoreOptions) {
        return null;
      }
      if (this.customStoreOptions.forceUniqueName) {
        return `${this.customStoreOptions.name}/${this.uniqueVuexStoreName}`;
      }
      return this.customStoreOptions.name;
    },
    getVuexProperty(prop, defaultValue) {
      if (this.$store.state[this.getVuexStoreName()]) {
        const property = this.$store.state[this.getVuexStoreName()][prop];
        return property !== undefined ? property : defaultValue;
      }
      return defaultValue;
    },
    setVuexProperty(prop, value) {
      if (this.$store.state[this.getVuexStoreName()]) {
        if (this.getVuexProperty(prop) !== undefined) {
          this.$store.commit(`${this.getVuexStoreName()}/onRegisterProperty`, {
            prop,
            value,
          });
        } else {
          this.$store.commit(`${this.getVuexStoreName()}/onSetPropertyState`, {
            prop,
            value,
          });
        }
      }
    },
    registerVuexModule(customStore, name, forceUniqueName, options) {
      if (typeof options === 'object') {
        this.options = Object.assign({}, this.options, options);
      }
      this.customStoreOptions = {
        name: name || this.$options.name,
        forceUniqueName,
      };

      const moduleName = this.getVuexStoreName();

      // eslint-disable-next-line
      if (
        typeof this.$store._modulesNamespaceMap[`${moduleName}/`] ===
        'undefined'
      ) {
        store.state = {};
        const storeObj = customStore || Object.assign({}, store);
        this.$store.registerModule(moduleName, storeObj);
        this.uniqueVuexStore = storeObj;
        this.isVuexStoreRegistered = true;
      }
    },
    unregisterVuexModule() {
      if (this.options.unregisterOnDeath) {
        this.$store.unregisterModule(this.getVuexStoreName());
      }
    },
  },

  provide() {
    return {
      getVuexProperty: this.getVuexProperty,
      setVuexProperty: this.setVuexProperty,
    };
  },
  beforeUnmount() {
    if (this.isVuexStoreRegistered) {
      this.unregisterVuexModule();
    }
  },
};
